<template>
    <div class="key-container">
        <div class="kb-line" v-for="line in btns">
            <div class="kb-btn clickable" v-for="btn in line"
                @click="clickBtn(btn)">{{btn.text}}</div>
        </div>
    </div>
</template>

<script>
    export default {
        props:["showPoint","color"],
        methods:{
            clickNum(num){
                this.$emit("clickNum",num)
            },
            clickPoint(){
                this.$emit("clickPoint")
            },
            clickDelete(){
                this.$emit("clickDelete")
            },

            clickBtn(item){
                item.click()
            }
        },
        computed:{
            btns(){
                let array = []
                array.push([
                        { text:"1", flex:1, click:()=>{this.clickNum(1)} },
                        { text:"2", flex:1, click:()=>{this.clickNum(2)} },
                        { text:"3", flex:1, click:()=>{this.clickNum(3)} },
                    ])
                array.push([
                        { text:"4", flex:1, click:()=>{this.clickNum(4)} },
                        { text:"5", flex:1, click:()=>{this.clickNum(5)} },
                        { text:"6", flex:1, click:()=>{this.clickNum(6)} },
                    ])
                array.push([
                        { text:"7", flex:1, click:()=>{this.clickNum(7)} },
                        { text:"8", flex:1, click:()=>{this.clickNum(8)} },
                        { text:"9", flex:1, click:()=>{this.clickNum(9)} },
                    ])
                array.push([
                    { text:".", flex:1, click:()=>{this.clickPoint()} },
                    { text:"0", flex:1, click:()=>{this.clickNum(0)} },
                    { text:"删除", flex:1, click:()=>{this.clickDelete()} },
                ])

                return array
            }

        },
    }
</script>

<style scoped>

    .key-container{
        margin-top: 20px;
    }

    .kb-line{
        display: flex;
    }

    .kb-btn{
        background: #2ca338;
        flex: 1;
        text-align: center;
        margin: 5px;
        border-radius: 5px;
        color: #FFF;
        height: 40px;
        line-height: 40px;
        font-size: 14px;

    }
</style>
